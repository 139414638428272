import {Component} from '@angular/core';
import {MatButtonModule} from "@angular/material/button";
import {MatIcon} from "@angular/material/icon";
import {AgGridAngular} from "ag-grid-angular";
import {ColDef, GridApi, GridReadyEvent} from "ag-grid-community";
import {PlayerInfoTableDefs} from "../player-info-table-defs";
import {IPlayerSuspension} from "../../../interfaces/player/IPlayerSuspension";
import {PlayersService} from "../../../services/players.service";
import {GameService} from "../../../services/game.service";
import {ErrorHandlingService} from "../../../services/error-handling.service";
import {MatDialog} from "@angular/material/dialog";
import {SuspendPlayerComponent} from "../../dialogs/suspend-player/suspend-player.component";
import {
  ResolvePlayerSuspensionComponent
} from "../../dialogs/resolve-player-suspension/resolve-player-suspension.component";
import {UIStateEnum} from "../../../enum/UIStateEnum";
import {MatProgressSpinner} from "@angular/material/progress-spinner";
import {concatMap, iif, of} from "rxjs";
import {HasPermissionDirective} from "../../../directives/permissions/has-permission";
import {PermissionTypes} from "../../../enum/PermissionTypes";
import {PermissionsService} from "../../../services/permissions.service";
import {AsyncPipe} from "@angular/common";

@Component({
  selector: 'app-player-suspensions-tab',
  standalone: true,
  imports: [
    MatButtonModule,
    MatIcon,
    AgGridAngular,
    MatProgressSpinner,
    HasPermissionDirective,
    AsyncPipe
  ],
  templateUrl: './player-suspensions-tab.component.html',
  styleUrl: './player-suspensions-tab.component.css'
})
export class PlayerSuspensionsTabComponent {

  public playerSuspensionsColumnDefs: ColDef[] = this.playerInfoTables.playerSuspensionColumnDefs;
  public playerSuspensions$ = this.playerService.selectSuspensions();
  public activePlayer = this.playerService.getActivePlayer();
  public suspensionsApi!: GridApi<IPlayerSuspension>;
  public uiState = UIStateEnum.ShowData;
  public uiStateForTemplate = UIStateEnum;

  protected readonly PermissionTypes = PermissionTypes;
  constructor(private playerInfoTables: PlayerInfoTableDefs,
              private playerService: PlayersService,
              private gameService: GameService,
              private errorHandlingService: ErrorHandlingService,
              private matDialog: MatDialog,
              private permissionsService: PermissionsService) {
  }

  openSuspendPlayerDialog() {
    if (!this.permissionsService.userHasPermission(PermissionTypes.ModifyPlayerSuspensions)){
      return;
    }
    this.uiState = UIStateEnum.ShowLoading;

    const dialogRef = this.matDialog.open(SuspendPlayerComponent, {
      data: this.activePlayer?.Id!,
      width: "650px"
    }).afterClosed().pipe(concatMap((res) =>
      iif(() => res, this.playerService.getPlayerSuspensionsForGame(this.activePlayer?.Id!, this.gameService.activeGame().Id), of(res))
    )).subscribe({
      next: () => {
        this.uiState = UIStateEnum.ShowData;
      },
      error: err => {
        this.uiState = UIStateEnum.ShowData;
        this.errorHandlingService.displayPageLevelErrorMessage(err);
      }
    });
  }

  onPlayerSuspensionsGridReady(params: GridReadyEvent<IPlayerSuspension>) {
    this.suspensionsApi = params.api;
    this.suspensionsApi.sizeColumnsToFit({
      defaultMinWidth: 100
    });
  }

  openPlayerSuspensionRowClick(chosenSuspension: any) {
    if (!this.permissionsService.userHasPermission(PermissionTypes.ModifyPlayerSuspensions)){
      return;
    }
    this.uiState = UIStateEnum.ShowLoading;
    const dialogRef = this.matDialog.open(ResolvePlayerSuspensionComponent, {
      data: chosenSuspension.data,
      width: "650px"
    }).afterClosed().pipe(concatMap((res) =>
      iif(() => res, this.playerService.getPlayerSuspensionsForGame(this.activePlayer?.Id!, this.gameService.activeGame().Id), of(res))
    )).subscribe({
      next: () => {
        this.uiState = UIStateEnum.ShowData;
      },
      error: err => {
        this.uiState = UIStateEnum.ShowData;
        this.errorHandlingService.displayPageLevelErrorMessage(err);
      }
    })
}
}
