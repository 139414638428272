import {Component} from '@angular/core';
import {OidcService} from "./services/oidc.service";
import {UIStateEnum} from "./enum/UIStateEnum";
import {onMainContentChange} from "./animations/side-nav-animations";
import {SideNavService} from "./services/side-nav.service";
import {Observable} from "rxjs";
import {NavigationStart, Router} from "@angular/router";
import {ErrorHandlingService} from "./services/error-handling.service";
import {IAppConfig} from "./interfaces/IAppConfig";
import {AppConfigService} from "./services/app-config.service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [onMainContentChange]
})
export class AppComponent {
  uiState: UIStateEnum = UIStateEnum.ShowLoading;
  uiStateEnumForTemplate = UIStateEnum;
  public errorMessage = '';
  public cdnRoot: string = '';
  public sideNavState$: Observable<any> = this.sideNavService.sideNavState$;

  constructor(private oidcService: OidcService,
              private router: Router,
              private appConfigService: AppConfigService,
              private errorHandlingService: ErrorHandlingService,
              private sideNavService: SideNavService) {
    this.retrieveAdminConfig();
    this.clearErrorMessagesOnNavigation();
  }

  private clearErrorMessagesOnNavigation() {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        this.errorHandlingService.clearErrorMessages();
      }
    });
  }

  private retrieveAdminConfig() {
    // get the org config from admin app server
    this.appConfigService.appConfigFromApiOrCache().subscribe({
      next: (gameConfigP: IAppConfig) => {
        // if auth config and org info is returned from server, set up auth config
        if (gameConfigP?.authConfig) {

          this.cdnRoot = gameConfigP.cdnRoot;
          this.oidcService.configureAuth(gameConfigP.authConfig)
            .then(() => {
              this.uiState = UIStateEnum.ShowData;
              // allow navigation now that the org and auth config have been stored
              this.router.initialNavigation();
            })
            .catch(() => {
              this.errorMessage = 'Looks like there was a problem configuring the authorization for the app. Please reload the page ' +
                'or contact your system administrator'
            });
        } else {
          this.errorMessage = 'Looks like there was a problem retrieving the app configuration. Please reload the page ' +
            'or contact your system administrator.';
        }

      }, error: () => {
        this.errorMessage = 'Looks like there was a problem retrieving the app configuration. Please reload the page ' +
          'or contact your system administrator.';
      }
    });
  }

}
