<div class="app-container">
  <app-game-banner></app-game-banner>
  <mat-sidenav-container class="sidenav-container">
    <mat-sidenav mode="side" opened>
      <app-side-nav></app-side-nav>
    </mat-sidenav>

    <mat-sidenav-content [@onMainContentChange]="(sideNavState$ | async) ? 'open': 'close'">
      <div class="main-content">
        <div>
          <app-page-display-error></app-page-display-error>
          <router-outlet></router-outlet>
          <ng-container>
            <app-component-level-error-message [errorMessage]="errorMessage"></app-component-level-error-message>
          </ng-container>
        </div>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>

