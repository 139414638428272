import {inject} from "@angular/core";
import {Router} from "@angular/router";
import {AdminAppModeService} from "../services/admin-app-mode.service";
import {map} from "rxjs";
import {catchError} from "rxjs/operators";
import {OidcService} from "../services/oidc.service";
import {ActiveUserService} from "../services/active-user.service";

export const CausableAdminGuard = () => {
  const adminAppModeService = inject(AdminAppModeService);
  const activeUserService = inject(ActiveUserService);
  const oidcService = inject(OidcService);

  const router = inject(Router);

  const is_authenticated = oidcService.isAuthenticated();
  if (!is_authenticated) {
    return router.navigateByUrl('auth/login');
  }

  const adminId = oidcService.getAdminIdFromClaims();

  if (adminId) {
    return activeUserService.retrieveActiveUser(adminId)
      .pipe(map((adminP) => {
          if (adminP.CausableAdmin) {
            adminAppModeService.enableCausableAdminMode();
            return true;
          }

          return false;
        }),
        catchError(() => {
          return router.navigateByUrl('not-admin');
        }))
  } else {
    return router.navigateByUrl('not-admin');
  }

};
