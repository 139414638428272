<h3>Player Notes</h3>
<div class="flex-col">
  <button mat-stroked-button
          data-testid="add-note-to-player-button"
          class="green-text margin-top-med"
          (click)="addPlayerNote();">
    <mat-icon>note_add</mat-icon>
    Add Note to Player
  </button>
</div>
<ag-grid-angular
  id="playerNotesGrid"
  class="ag-theme-quartz margin-top-med large-table"
  data-testid="player-notes-grid"
  [tooltipShowDelay]=0
  rowClass="table-row"
  overlayNoRowsTemplate="<span>No notes for this player</span>"
  (gridReady)="onPlayerNotesGridReady($event)"
  (rowClicked)="onPlayerNotesRowClicked($event)"
  [rowData]="playerNotes"
  [pagination]="true"
  [columnDefs]="playerNotesColumnDefs">
</ag-grid-angular>
