import {Component} from '@angular/core';
import {IGameQueryResult} from "../../interfaces/IGameQueryResult";
import {GameService} from "../../services/game.service";
import {Router} from "@angular/router";
import {GameInstanceService} from "../../services/game-instance.service";
import {GamePageBase} from "../../shared/game-page-base";
import {FormControl, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {MatError, MatFormField, MatLabel} from "@angular/material/form-field";
import {MatOption, MatSelect} from "@angular/material/select";
import {MatButton} from "@angular/material/button";
import {
  UserHasCausableAdminAccessDirective
} from "../../directives/permissions/user-has-causable-admin-access.directive";
import {AdminAppModeService} from "../../services/admin-app-mode.service";

@Component({
  selector: 'app-choose-game',
  templateUrl: './choose-game.component.html',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    MatFormField,
    MatError,
    MatSelect,
    MatOption,
    MatLabel,
    MatButton,
    UserHasCausableAdminAccessDirective
  ],
  styleUrls: ['./choose-game.component.scss']
})
export class ChooseGameComponent extends GamePageBase {

  public chosenGameFromControl: FormControl<IGameQueryResult | null> = new FormControl<IGameQueryResult | null>(null, Validators.required);
  public chosenGameFromGroup: FormGroup = new FormGroup({
    chosenGame: this.chosenGameFromControl
  });
  public availableGames: IGameQueryResult[] = this.gameService.getAllAvailableGames();

  constructor(gameService: GameService,
              private gameInstanceService: GameInstanceService,
              private adminAppModeService: AdminAppModeService,
              private router: Router) {
    super(gameService);
    this.gameService.clearActiveGame();
    this.gameInstanceService.clearActiveGameInstance();
  }

  async onGameConfirmClick() {
    await this.updateAndNavigateToActiveGame();
  }

  private async updateAndNavigateToActiveGame() {

    if (!this.chosenGameFromGroup.valid) {
      this.chosenGameFromGroup.markAllAsTouched();
      return;
    }

    this.adminAppModeService.enableGameAdminMode();
    this.gameService.updateActiveGame(this.chosenGameFromControl.value!);
    await this.router.navigateByUrl(`${this.chosenGameFromControl.value?.Id}/active-game-instances`);

  }

  async toggleCausableAdminMode() {
    this.adminAppModeService.enableCausableAdminMode();
    await this.router.navigateByUrl(`causable-admin-dashboard`);
  }
}
