<div class="two-column-page-container">

  @if (chosenPlayerInfo) {
    @if ((playerSuspensions$ | async)!.length > 0) {
      <div class="error-message-container margin-bottom-large" data-testid="player-suspended-banner"><p class="error-message">Player Suspended</p></div>
    }
    <h2 data-testid="chosen-player-name">{{ chosenPlayerInfo.FirstName }} {{ chosenPlayerInfo.LastName }}
      <small data-testid="chosen-player-id">({{ chosenPlayerInfo.Id }})</small>
      <button
        mat-mini-fab
        data-testid="open-manage-game-options-menu-button"
        id="charity-menu-button"
        class="float-right primary-background-color"
        *hasPermission="permissionTypes.UpdatePlayerActivationStatus"
        [matMenuTriggerFor]="menu">
        <mat-icon matTooltipPosition="right"
                  matTooltip="open admin user menu"
        >more_vertical
        </mat-icon>
      </button>
    </h2>
    <div class="margin-bottom-med">
      <button mat-raised-button (click)="chooseNewPlayerClick()" data-testid="chosen-player-back-button">
        <mat-icon>arrow_back_ios</mat-icon>
        Back
      </button>
    </div>
    <div class="flex-row-wrap justify-space-between">
      <div class="flex-50">
        <mat-card class="margin-bottom-med">
          <mat-card-content>
            @if (uiState === uiStateForTemplate.ShowData) {
              <h4>General Info</h4>

              <div class="flex-row-wrap justify-start gap-normal margin-bottom-med margin-top-med">
                <div class="detail-section">
                  <div class="detail-label">Player Name</div>
                  <div class="detail-value"
                       data-testid="manage-player-name">{{ chosenPlayerInfo.FirstName }} {{ chosenPlayerInfo.LastName }}
                  </div>
                </div>

                <div class="detail-section">
                  <div class="detail-label">E-mail</div>
                  <div class="detail-value" data-testid="manage-player-email">{{ chosenPlayerInfo.Email }}</div>
                </div>

                <div class="detail-section">
                  <div class="detail-label">Player Status</div>
                  <div class="detail-value" data-testid="manage-player-status">
                <span
                  [class]="chosenPlayerInfo.Active ? 'green-text': 'red-text'">{{ chosenPlayerInfo.Active ? 'Active' : 'Deactivated' }}</span>
                  </div>
                </div>

                <div class="detail-section">
                  <div class="detail-label">Phone</div>
                  <div class="detail-value" data-testid="manage-player-phone">{{ chosenPlayerInfo.Phone }}</div>
                </div>

                <div class="detail-section">
                  <div class="detail-label">Player Since</div>
                  <div class="detail-value"
                       data-testid="manage-player-created-on">{{ chosenPlayerInfo.CreatedOn | date: 'yyyy-MM-dd' }}
                  </div>
                </div>

                <div class="detail-section">
                  <div class="detail-label">Last Logged in</div>
                  <div class="detail-value"
                       data-testid="manage-player-last-logged-on">{{ chosenPlayerInfo.MetaData.LastLoggedIn.toString() === MinDateTime ? "N/A" : chosenPlayerInfo.MetaData.LastLoggedIn | date: 'yyyy-MM-dd h:mm a' }}
                  </div>
                </div>

                <div class="detail-section">
                  <div class="detail-label">Last Time Played</div>
                  <div class="detail-value"
                       data-testid="last-time-played">{{ chosenPlayerInfo.MetaData.LastTimePlayed.toString() === MinDateTime ? "N/A" : chosenPlayerInfo.MetaData.LastTimePlayed | date: 'yyyy-MM-dd h:mm a' }}
                  </div>
                </div>

                <div class="detail-section">
                  <div class="detail-label">Number of Draws played in</div>
                  <div class="detail-value"
                       data-testid="draws-played-in">{{ chosenPlayerInfo.MetaData.TotalInstancesPlayedPerGame }}
                  </div>
                </div>

                <div class="detail-section">
                  <div class="detail-label">Total dollar amount spent in Game</div>
                  <div class="detail-value"
                       data-testid="total-spent-on-game">{{ chosenPlayerInfo.MetaData.TotalSpentForGame | formatCurrency }}
                  </div>
                </div>

                <div class="detail-section">
                  <div class="detail-label">Agreed To Terms Of Use & Privacy Policy On</div>
                  <div data-testid="manage-player-agreed-to-tou-privacy-policy-on"
                       class="detail-value">{{ chosenPlayerInfo.AgreedToTermsOfUsePrivacyPolicyOn ? (chosenPlayerInfo.AgreedToTermsOfUsePrivacyPolicyOn | date: 'yyyy-MM-dd') : 'Has not agreed'}}
                  </div>
                </div>
              </div>
              @if (chosenPlayerInfo.PlayerCharityPledge) {
                <h4>Charity Pledge</h4>

                <div class="flex-row-wrap justify-start gap-normal margin-bottom-large">
                  <div class="detail-section">
                    <div class="detail-label">Charity Name</div>
                    <div class="detail-value"
                         data-testid="manage-player-charity-name">{{ chosenPlayerInfo.PlayerCharityPledge.CharityName }}
                    </div>
                  </div>

                  <div class="detail-section">
                    <div class="detail-label">Charity Id</div>
                    <div class="detail-value"
                         data-testid="manage-player-charity-id">{{ chosenPlayerInfo.PlayerCharityPledge.CharityId }}
                    </div>
                  </div>

                  <div class="detail-section">
                    <div class="detail-label">Last Updated On</div>
                    <div data-testid="manage-player-charity-pledge-updated-on"
                         class="detail-value">{{ chosenPlayerInfo.PlayerCharityPledge.CreatedOn | date: 'MMM d, y HH:mm' }}
                    </div>
                  </div>
                </div>
              }

            } @else if (uiState === uiStateForTemplate.ShowLoading) {
              <mat-spinner></mat-spinner>
            }
          </mat-card-content>
        </mat-card>

      </div>
      <div class="flex-50">
        <mat-card class="margin-bottom-med">
          <mat-card-content>
            <app-player-game-info></app-player-game-info>
          </mat-card-content>
        </mat-card>
      </div>
    </div>
  }
</div>
<mat-menu #menu="matMenu">

  @if (chosenPlayerInfo) {
    <button mat-menu-item
            *hasPermission="permissionTypes.AdminUpdatePlayerBasicInfo"
            (click)="onUpdateBasicInfoClick()"
            data-testid="edit-category-name-option">
      <mat-icon class="dark-grey menu-icon">edit</mat-icon>
      <span class="dark-grey menu-text">Edit Basic Info</span>
    </button>
    <ng-container *hasPermission="permissionTypes.UpdatePlayerActivationStatus">
      @if (chosenPlayerInfo.Active) {
        <button
          mat-menu-item
          data-testid="deactivate-player-button"
          class="red-text float-right"
          (click)="onDeactivatePlayerClick()">
          Deactivate player
          <mat-icon class="red-text">do_disturb</mat-icon>
        </button>
      } @else {
        <button
          mat-menu-item
          data-testid="reactivate-player-button"
          class="green-text float-right"
          (click)="onReactivatePlayerClick()">
          Reactivate player
          <mat-icon>refresh</mat-icon>
        </button>
      }
    </ng-container>
  }
</mat-menu>
