import {Component, Inject} from '@angular/core';
import {UIStateEnum} from "../../../enum/UIStateEnum";
import {SnackbarService} from "../../../services/snackbar.service";
import {ErrorHandlingService} from "../../../services/error-handling.service";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {PlayersService} from "../../../services/players.service";
import {IDeactivatePlayerNumbers} from "../../../interfaces/player/IDeactivatePlayerNumbers";
import {IPlayerNumberQueryResult} from "../../../interfaces/player/IPlayerNumberQueryResult";
import {
  IPlayerNumberWithCheckedForUpdatingStatus
} from "../../../interfaces/player/IPlayerNumberWithCheckedForUpdatingStatus";
import {DialogBaseComponent} from "../dialog-base/dialog-base.component";
import {MatCheckbox} from "@angular/material/checkbox";
import {FormsModule} from "@angular/forms";
import {MatButton} from "@angular/material/button";
import {GameService} from "../../../services/game.service";
import {GameTypeEnum} from "../../../enum/GameTypeEnum";
import {MatTableDataSource, MatTableModule} from "@angular/material/table";
import {SelectionModel} from "@angular/cdk/collections";
import {IGoldRushStrip} from "../../../interfaces/IGoldRushStrip";



@Component({
  selector: 'app-deactivate-player-numbers',
  standalone: true,
  templateUrl: './deactivate-player-numbers.component.html',
  imports: [
    DialogBaseComponent,
    MatCheckbox,
    FormsModule,
    MatButton,
    MatTableModule
  ],
  styleUrls: ['./deactivate-player-numbers.component.css']
})
export class DeactivatePlayerNumbersComponent {
  public uiState: UIStateEnum = UIStateEnum.ShowData;
  public uiStateEnumForTemplate = UIStateEnum;
  public activeNumbers: IPlayerNumberWithCheckedForUpdatingStatus[] = [];
  public gameType: GameTypeEnum = this.gameService.activeGame().Type;
  public gameTypes = GameTypeEnum;
  selectedStripsForDeactivation = new SelectionModel<IGoldRushStrip>(true, []);
  activeStripsDataSource = new MatTableDataSource<IGoldRushStrip>();
  displayedColumns: string[] = ['Select', 'GoldRushStripNumber', 'Numbers'];

  constructor(private snackBarService: SnackbarService,
              private errorHandlingService: ErrorHandlingService,
              private playersService: PlayersService,
              private gameService: GameService,
              private matDialogRef: MatDialogRef<DeactivatePlayerNumbersComponent>,
              @Inject(MAT_DIALOG_DATA) public deactivateRequestData: {
                PlayerId: string;
                GameId: string;
                AllPlayerTickets: IPlayerNumberQueryResult[]
              }) {
    this.activeNumbers = this.deactivateRequestData.AllPlayerTickets.filter((numberP) => numberP.Active);

    if (this.gameType === GameTypeEnum.GoldRush) {
      this.activeStripsDataSource.data = this.organizeIntoStrips();
    }
  }

  getNumbersString(numbersP: IPlayerNumberQueryResult[]): string {
    return numbersP.map((number) => number.TicketNumber).toString();
  }

  organizeIntoStrips(): IGoldRushStrip[] {
    return this.activeNumbers.reduce((acc: IGoldRushStrip[], currentValue) => {
      const existingGroup = acc.find(group => group.GoldRushStripNumber === currentValue.GoldRushStripNumber);
      if (existingGroup) {
        existingGroup.Numbers.push(currentValue);
      } else {
        acc.push({GoldRushStripNumber: currentValue.GoldRushStripNumber, Numbers: [currentValue]});
      }
      return acc;
    }, []);
  }

  isAllSelected() {
    const numSelected = this.selectedStripsForDeactivation.selected.length;
    const numRows = this.activeStripsDataSource.data.length;
    return numSelected === numRows;
  }

  toggleAllRows() {
    if (this.isAllSelected()) {
      this.selectedStripsForDeactivation.clear();
      return;
    }

    this.selectedStripsForDeactivation.select(...this.activeStripsDataSource.data);
  }

  deactivateNumbers() {
    let deactivationRequest: IDeactivatePlayerNumbers | null;

    if (this.gameType == GameTypeEnum.GoldRush) {
      deactivationRequest = this.buildDeactivateGoldRushStripsRequest();
    } else {
      deactivationRequest = this.buildDeactivate5050NumbersRequest();
    }

    if (deactivationRequest) {
      this.uiState = UIStateEnum.ShowLoading;

      this.playersService.deactivatePlayerNumbersByGroup(deactivationRequest).subscribe({
        next: () => {
          this.snackBarService.openSuccessfulSnackBar('You have successfully deactivated the players numbers');
          this.matDialogRef.close(true);
        },
        error: (err) => {
          this.uiState = UIStateEnum.ShowData;
          this.errorHandlingService.displayDialogLevelErrorMessage(err, true);
        }
      })
    }

  }

  buildDeactivate5050NumbersRequest(): IDeactivatePlayerNumbers | null {
    const checkedNumbers = this.activeNumbers
      .filter(numberP => numberP.Checked)
      .map(checkedNumberP => checkedNumberP.Id);

    if (checkedNumbers.length === 0) {
      this.snackBarService.openErrorSnackBar('you must select at least one number to deactivate')
      return null;
    }

    return {
      PlayerNumberIds: checkedNumbers,
      PlayerId: this.deactivateRequestData.PlayerId,
      GameId: this.deactivateRequestData.GameId
    }
  }

  buildDeactivateGoldRushStripsRequest(): IDeactivatePlayerNumbers | null {
    const numberIds = this.selectedStripsForDeactivation.selected.flatMap((strip) => strip.Numbers.map((number) => number.Id))

    if (numberIds.length > 0) {
      return {
        PlayerNumberIds: numberIds,
        PlayerId: this.deactivateRequestData.PlayerId,
        GameId: this.deactivateRequestData.GameId
      }
    }

    this.snackBarService.openErrorSnackBar('you must select at least one strip to deactivate')
    return null;
  }
}
