import { Component } from '@angular/core';
import {UIStateEnum} from "../../../enum/UIStateEnum";
import {NgIf} from "@angular/common";
import {FormControl, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {MatFormField} from "@angular/material/form-field";
import {MatInputModule} from "@angular/material/input";
import {MatButton} from "@angular/material/button";
import {SnackbarService} from "../../../services/snackbar.service";
import {IEmailBlast} from "../../../interfaces/IEmailBlast";
import {ActiveUserService} from "../../../services/active-user.service";
import {GameInstanceService} from "../../../services/game-instance.service";
import {EmailBlastService} from "../../../services/email-blast.service";
import {MatDialogRef} from "@angular/material/dialog";
import {ErrorHandlingService} from "../../../services/error-handling.service";
import {DialogBaseComponent} from "../dialog-base/dialog-base.component";

@Component({
  selector: 'app-email-blast',
  standalone: true,
  imports: [
    NgIf,
    ReactiveFormsModule,
    MatFormField,
    MatInputModule,
    MatButton,
    DialogBaseComponent,
  ],
  templateUrl: './email-blast.component.html',
  styleUrl: './email-blast.component.css'
})
export class EmailBlastComponent {

  public uiState: UIStateEnum = UIStateEnum.ShowData;
  public uiStateEnumForTemplate = UIStateEnum;

  subjectControl = new FormControl<string|null>("", Validators.required);
  messageControl = new FormControl<string|null>("", Validators.required);

  emailForm = new FormGroup({
    subject: this.subjectControl,
    message: this.messageControl
  });

  constructor(private snackbarService: SnackbarService,
              private gameInstanceService: GameInstanceService,
              private activeUserService: ActiveUserService,
              private emailBlastService: EmailBlastService,
              private matDialogRef: MatDialogRef<EmailBlastComponent>,
              private errorHandlingService: ErrorHandlingService) {
  }

  sendEmailBlast() {
    if (!this.emailForm.valid) {
      this.emailForm.markAllAsTouched();
      return;
    }

    this.uiState = UIStateEnum.ShowLoading;

    let command: IEmailBlast = {
      AdminId: this.activeUserService.activeUser().Id,
      GameId: this.gameInstanceService.activeGameInstance().GameId,
      GameInstanceId: this.gameInstanceService.activeGameInstance().Id,
      Message: this.messageControl.value!,
      Subject: this.subjectControl.value!
    };

    this.emailBlastService.sendEmailBlast(command).subscribe({
      next: () => {
        this.snackbarService.openSuccessfulSnackBar("Message Sent!");
        this.matDialogRef.close();
      },
      error: err => {
        this.uiState = UIStateEnum.ShowData;
        this.errorHandlingService.displayDialogLevelErrorMessage(err);
      }
    });

  }
}
