<button mat-icon-button
        (click)="onViewCharityClick()"
        class="primary-button row-button"
        matTooltip="View Charity Info"
        matTooltipPosition="right"
        matTooltipShowDelay="500"
        data-testid="view-charity-button">
  <mat-icon>info_outline</mat-icon>
</button>{{rowData.CharityName}}

