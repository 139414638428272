import {inject} from "@angular/core";
import {PermissionsService} from "../services/permissions.service";
import {PermissionTypes} from "../enum/PermissionTypes";
import {Router} from "@angular/router";

export const PermissionGuard = (permissionTypeP: PermissionTypes) => {
  const permissionsService = inject(PermissionsService);
  const router = inject(Router);
  let userHasPermission = permissionsService.userHasPermission(permissionTypeP);
  permissionsService.userHasPermission(permissionTypeP);
  if (!userHasPermission) {
    router.navigateByUrl('not-authorized');
  }
  return userHasPermission;
};
