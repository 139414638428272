import {Injectable} from '@angular/core';
import {ColDef} from "ag-grid-community";
import {
  GameAdminSettingsComponent
} from "../components/cell-renderers/game-admin-settings/game-admin-settings.component";
import {
  OrgCausableSettingsComponent
} from "../components/cell-renderers/org-causable-settings/org-causable-settings.component";
import {DatePipe} from "@angular/common";
import {
  GenericAdminSettingsComponent
} from "../components/cell-renderers/generic-admin-settings/generic-admin-settings.component";
import {FormatBoolean} from "../components/cell-renderers/show-played/format-boolean.component";
import {
  CharityAdminSettingsComponent
} from "../components/cell-renderers/charity-admin-settings/charity-admin-settings.component";

@Injectable({
  providedIn: 'root'
})
export class AdminUserTables {

  private verificationCode: ColDef = {
    headerName: 'Verification Code',
    field: 'VerificationCode',
    width: 150, minWidth: 50, maxWidth: 150,
    resizable: true,
    sortable: true
  };

  private fullNameColDef: ColDef = {
    headerName: 'Name',
    valueGetter: (rowData) => {
      return rowData.data.FirstName + ' ' + rowData.data.LastName
    },
    resizable: true,
    sortable: true
  };

  private emailColDef: ColDef = {
    headerName: 'Email',
    resizable: true,
    width: 250, minWidth: 200, maxWidth: 250,
    sortable: true,
    field: 'Email'
  };

  constructor(private datePipe: DatePipe) {
  }

  public gameAdminUserTableColDefs: ColDef[] = [
    this.fullNameColDef,
    {
      headerName: 'User Role(s)',
      resizable: true,
      sortable: true,
      width: 250, minWidth: 200, maxWidth: 250,
      field: 'Roles',
      valueFormatter: (params) => {
        return params.value.map((role: any) => role.Name).join(', ');
      },
    },
    this.emailColDef,
    {
      headerName: 'Confirmed On',
      resizable: true,
      sortable: true,
      field: 'ConfirmedOn',
      valueFormatter: (params) => {
        if(params.value) {
          return this.datePipe.transform(params.value)!;
        }
        return 'User Not Confirmed';
      },
    },
    {
      headerName: 'Active',
      sortable: true,
      width: 150, minWidth: 150, maxWidth: 150,
      cellRenderer: FormatBoolean,
      field: 'Active'
    },
    {
      headerName: '',
      width: 150, minWidth: 50, maxWidth: 150,
      cellRenderer: GameAdminSettingsComponent
    }
  ];

  public charityAdminUserTableColDefs: ColDef[] = [
    this.fullNameColDef,
    {
      headerName: 'Charities',
      resizable: true,
      sortable: true,
      width: 250, minWidth: 200, maxWidth: 250,
      field: 'CharityNames',
      valueFormatter: (params) => {
        return params.value.map((charity: string) => charity).join(', ');
      },
    },
    this.emailColDef,
    {
      headerName: 'Confirmed On',
      resizable: true,
      sortable: true,
      field: 'ConfirmedOn',
      valueFormatter: (params) => {
        if(params.value) {
          return this.datePipe.transform(params.value)!;
        }
        return 'User Not Confirmed';
      },
    },
    {
      headerName: 'Active',
      sortable: true,
      width: 150, minWidth: 150, maxWidth: 150,
      cellRenderer: FormatBoolean,
      field: 'Active'
    },
    {
      headerName: '',
      width: 150, minWidth: 50, maxWidth: 150,
      cellRenderer: CharityAdminSettingsComponent
    }
  ];

  public causableAdminColDefs: ColDef[] = [
    this.fullNameColDef,
    this.emailColDef,
    {
      headerName: 'Active',
      resizable: true,
      sortable: true,
      cellRenderer: FormatBoolean,
      field: 'Active'
    },
    this.verificationCode,
    {
      headerName: '',
      width: 150, minWidth: 50, maxWidth: 150,
      cellRenderer: OrgCausableSettingsComponent
    },
  ];

  public genericAdminColDefs: ColDef[] = [
    this.fullNameColDef,
    this.emailColDef,
    {
      headerName: 'Confirmed On',
      resizable: true,
      sortable: true,
      field: 'ConfirmedOn',
      valueFormatter: (params) => {
        if(params.value) {
          return this.datePipe.transform(params.value)!;
        }
        return 'User Not Confirmed';
      },
    },
    {
      headerName: 'Active',
      sortable: true,
      width: 150, minWidth: 150, maxWidth: 150,
      cellRenderer: FormatBoolean,
      field: 'Active'
    },
    {
      headerName: '',
      width: 150, minWidth: 50, maxWidth: 150,
      cellRenderer: GenericAdminSettingsComponent
    }
  ];

}
