import {Component} from '@angular/core';
import {ICellRendererAngularComp} from "ag-grid-angular";
import {ICellRendererParams} from "ag-grid-community";
import {ICharityPaymentBalance} from "../../../interfaces/reporting/ICharityPaymentBalance";
import {MatDialog} from "@angular/material/dialog";
import {CharityInfoComponent} from "../../dialogs/charity-info/charity-info.component";
import {MatIconButton} from "@angular/material/button";
import {MatTooltip} from "@angular/material/tooltip";
import {MatIcon} from "@angular/material/icon";

@Component({
  selector: 'app-charity-payouts-table-buttons',
  standalone: true,
  templateUrl: './charity-payout-charity-info.component.html',
  imports: [
    MatIconButton,
    MatTooltip,
    MatIcon
  ],
  styleUrls: ['./charity-payout-charity-info.component.css']
})
export class CharityPayoutCharityInfoComponent implements ICellRendererAngularComp {

  public rowData!: ICharityPaymentBalance;

  constructor(private matDialog: MatDialog) {
  }

  agInit(params: ICellRendererParams): void {
    this.rowData = params.data;
  }

  refresh(params: ICellRendererParams<any>): boolean {
    return false;
  }

  onViewCharityClick() {
    this.matDialog.open(CharityInfoComponent, {
      width: '650px'
    });
  }

}
