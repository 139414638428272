import {Injectable} from '@angular/core';
import {APIService} from "./api.service";
import {IPlayerNote} from "../interfaces/player/IPlayerNote";
import {IPlayerNoteCommand} from "../interfaces/player/IPlayerNoteCommand";

@Injectable({
  providedIn: 'root'
})
export class PlayerGameNoteService {

  constructor(private apiService: APIService) {
  }

  public addPlayerGameNote(noteP: IPlayerNoteCommand) {
    return this.apiService.MakePostRequest<IPlayerNote>("player/add-note", noteP);
  }

  public updatePlayerGameNote(noteP: IPlayerNoteCommand) {
    return this.apiService.MakePutRequest<IPlayerNote>("player/update-note", noteP);
  }
}
