import {Component} from '@angular/core';
import {ColDef, GridApi, GridReadyEvent} from "ag-grid-community";
import {IPlayerPaymentTransactions} from "../../../interfaces/player/IPlayerPaymentTransactions";
import {IPlayerPerGameQueryResult} from "../../../interfaces/player/IPlayerPerGameQueryResult";
import {PermissionTypes} from "../../../enum/PermissionTypes";
import {IGameQueryResult} from "../../../interfaces/IGameQueryResult";
import {UIStateEnum} from "../../../enum/UIStateEnum";
import {FormControl, FormGroup, ReactiveFormsModule} from "@angular/forms";
import {GameService} from "../../../services/game.service";
import {PlayerInfoTableDefs} from "../player-info-table-defs";
import {DateService} from "../../../services/date.service";
import {PlayersService} from "../../../services/players.service";
import {ErrorHandlingService} from "../../../services/error-handling.service";
import {IPaymentTier} from "../../../interfaces/player/IPaymentTier";
import {FormatCurrencyPipe} from "../../../pipes/format-currency.pipe";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatInput} from "@angular/material/input";
import {MatDatepickerModule} from "@angular/material/datepicker";
import {MatTooltip} from "@angular/material/tooltip";
import {MatButton} from "@angular/material/button";
import {AgGridAngular} from "ag-grid-angular";
import {MatIcon} from "@angular/material/icon";

@Component({
  selector: 'app-player-transactions-tab',
  standalone: true,
  templateUrl: './player-transactions-tab.component.html',
  imports: [
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInput,
    MatIcon,
    MatDatepickerModule,
    MatTooltip,
    MatButton,
    AgGridAngular
  ],
  styleUrl: './player-transactions-tab.component.scss'
})
export class PlayerTransactionsTabComponent {

  public chosenPlayer: IPlayerPerGameQueryResult | undefined = this.playerService.getActivePlayer();
  public permissionTypes = PermissionTypes;
  public activeGame: IGameQueryResult | undefined = this.gameService.activeGame();
  public playerTransactionsPerGame: IPlayerPaymentTransactions[] = [];
  public filteredTransactionsPerGame: IPlayerPaymentTransactions[] = [];
  public transactionGridApi!: GridApi<IPlayerPaymentTransactions>;
  public transactionColumnDefs: ColDef[] = this.playerInfoTables.transactionColumnDefs;
  public availableTiersPerGame = this.gameService.getActiveGamePaymentTiers();
  public uiState: UIStateEnum = UIStateEnum.ShowData;
  public uiStateForTemplate = UIStateEnum;

  public transactionSearchForm = new FormGroup(
    {
      chargeId: new FormControl(''),
      transactionDate: new FormControl(''),
      ticketNumber: new FormControl('')
    }
  );

  constructor(private gameService: GameService,
              private playerService: PlayersService,
              private errorHandlingService: ErrorHandlingService,
              private dateService: DateService,
              private playerInfoTables: PlayerInfoTableDefs,
              private formatCurrencyPipe: FormatCurrencyPipe) {
    this.getPlayerTransactions();
  }

  getPlayerTransactions() {
    this.uiState = UIStateEnum.ShowLoading;
    this.playerService.getPlayerTransactionsByGameId(this.chosenPlayer?.Id!, this.activeGame?.Id!).subscribe({
      next: (playerTransactionsP) => {
        playerTransactionsP = playerTransactionsP
          .map((transaction) => {
            const tier = this.availableTiersPerGame?.find((tier) => tier.Id === transaction.PaymentTierId);
            if (tier) {
              transaction.PaymentTierString = this.formatTierToReadableName(tier);
            }
            return transaction;
          });
        this.playerTransactionsPerGame = playerTransactionsP;
        this.filteredTransactionsPerGame = playerTransactionsP.sort((a, b) => new Date(a.CreatedOn).getDate() - new Date(b.CreatedOn).getDate());
        this.uiState = UIStateEnum.ShowData;
      }, error: (err) => {
        this.errorHandlingService.displayPageLevelErrorMessage(err, 'Looks there was an issue retrieving player transactions.' +
          ' Please try reloading and if the problem persists contact a system administrator');
      }
    });
  }

  onTransactionGridReady(params: GridReadyEvent<IPlayerPaymentTransactions>) {
    this.transactionGridApi = params.api;
    this.transactionGridApi.sizeColumnsToFit({
      defaultMinWidth: 100,
    });
  }

  onResetClick() {
    this.transactionSearchForm.reset();
    this.filteredTransactionsPerGame = this.playerTransactionsPerGame;
  }

  onFormSubmit() {
    if (!this.transactionSearchForm.valid) {
      this.transactionSearchForm.markAllAsTouched();
      return;
    }
    this.filteredTransactionsPerGame = this.playerTransactionsPerGame.filter((transactionP) => {
      let ticketNumberFilterPasses: boolean = true;
      let stripeIdFilterPasses: boolean = true;
      let dateFilterPasses: boolean = true;

      if (this.transactionSearchForm.value.ticketNumber) {
        ticketNumberFilterPasses = transactionP.TicketNumbers.includes(Number(this.transactionSearchForm.value.ticketNumber));
      }

      if (this.transactionSearchForm.value.chargeId) {
        stripeIdFilterPasses = transactionP.StripeChargeId.includes(this.transactionSearchForm.value.chargeId);
      }

      if (this.transactionSearchForm.value.transactionDate) {
        const parsedDate = new Date(this.transactionSearchForm.value.transactionDate);
        dateFilterPasses = this.dateService.areDatesSameDay(transactionP.CreatedOn, parsedDate)
      }


      return ticketNumberFilterPasses && stripeIdFilterPasses && dateFilterPasses;
    })
  }

  formatTierToReadableName(tierP: IPaymentTier) {
    return `${tierP.NumberOfTickets} for ${this.formatCurrencyPipe.transform(tierP.Price)}`;
  }
}
