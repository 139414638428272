import {Component} from '@angular/core';
import {UIStateEnum} from "../../enum/UIStateEnum";
import {PermissionTypes} from "../../enum/PermissionTypes";
import {MatTabsModule} from "@angular/material/tabs";
import {PlayerNumbersTabComponent} from "./player-numbers-tab/player-numbers-tab.component";
import {PlayerTransactionsTabComponent} from "./player-transactions-tab/player-transactions-tab.component";
import {HasPermissionDirective} from "../../directives/permissions/has-permission";
import {PlayerGameNotesTabComponent} from "./player-game-notes-tab/player-game-notes-tab.component";
import {MatProgressSpinner} from "@angular/material/progress-spinner";
import {PlayerSuspensionsTabComponent} from "./player-suspensions-tab/player-suspensions-tab.component";

@Component({
  selector: 'app-player-game-info',
  standalone: true,
  templateUrl: './player-game-info.component.html',
  imports: [
    MatTabsModule,
    PlayerNumbersTabComponent,
    PlayerTransactionsTabComponent,
    HasPermissionDirective,
    PlayerGameNotesTabComponent,
    MatProgressSpinner,
    PlayerSuspensionsTabComponent
  ],
  styleUrls: ['./player-game-info.component.scss']
})
export class PlayerGameInfoComponent {

  public permissionTypes = PermissionTypes;
  public uiState: UIStateEnum = UIStateEnum.ShowData;
  public uiStateForTemplate = UIStateEnum;

  constructor() {
  }
}
