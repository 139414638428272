import {Component, inject} from '@angular/core';
import {DialogBaseComponent} from "../../dialogs/dialog-base/dialog-base.component";
import {MatButton} from "@angular/material/button";
import {MatError, MatFormField, MatHint, MatLabel, MatSuffix} from "@angular/material/form-field";
import {MatInput} from "@angular/material/input";
import {NgIf} from "@angular/common";
import {FormControl, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {UIStateEnum} from "../../../enum/UIStateEnum";
import {MatDatepicker, MatDatepickerInput, MatDatepickerToggle} from "@angular/material/datepicker";
import {GameService} from "../../../services/game.service";
import {IApiErrorResponse} from "../../../interfaces/error/IApiErrorResponse";
import {SnackbarService} from "../../../services/snackbar.service";
import {LotteryLicenceService} from "../../../services/lottery-licence.service";

@Component({
  selector: 'app-lottery-licence-add',
  standalone: true,
  imports: [
    DialogBaseComponent,
    MatButton,
    MatError,
    MatFormField,
    MatHint,
    MatInput,
    MatLabel,
    NgIf,
    ReactiveFormsModule,
    MatDatepicker,
    MatDatepickerInput,
    MatDatepickerToggle,
    MatSuffix
  ],
  templateUrl: './lottery-licence-add.component.html',
  styleUrl: './lottery-licence-add.component.css'
})
export class LotteryLicenceAddComponent {
  private gameService: GameService = inject(GameService);
  private lotteryLicenceService: LotteryLicenceService = inject(LotteryLicenceService);
  private snackbarService: SnackbarService = inject(SnackbarService);

  public uiState: UIStateEnum = UIStateEnum.ShowData;
  public uiStateEnumForTemplate = UIStateEnum;

  licenceNumberControl = new FormControl<string|null>("", Validators.required);
  startDateControl = new FormControl<string|null>("", Validators.required);
  endDateControl = new FormControl<string|null>("");

  licenceForm = new FormGroup({
    licenceNumber: this.licenceNumberControl,
    startDate: this.startDateControl,
    endDate: this.endDateControl,
  });

  addNewLicenceNumber() {
    if(!this.licenceForm.valid) {
      this.licenceForm.markAllAsTouched();
      return;
    }

    this.uiState = UIStateEnum.ShowLoading;

    this.lotteryLicenceService.addLotteryLicence({
      gameId: this.gameService.activeGame().Id!,
      licenceNumber: this.licenceNumberControl.value!,
      endDate: this.endDateControl.value!,
      startDate: this.startDateControl.value!,
    }).subscribe({
      next: () => {
        this.uiState = UIStateEnum.ShowData;
        this.snackbarService.openSuccessfulSnackBar('Successfully added new licence number');
      }, error: (res: IApiErrorResponse) => {
        this.uiState = UIStateEnum.ShowData;
        if (res.Error?.ClientErrorMessages?.length > 0){
          this.snackbarService.openErrorSnackBar(res.Error.ClientErrorMessages.toString());
        } else {
          this.snackbarService.openErrorSnackBar('There was an issue adding your new licence number');
        }
      }
    })
  }
}
