import {Injectable} from '@angular/core';
import {concatMap, Observable} from 'rxjs';
import {createStore, select, setProp, withProps} from "@ngneat/elf";
import {APIService} from "./api.service";
import {getAllEntities, selectAllEntities, setEntities, withEntities} from "@ngneat/elf-entities";
import {IGameQueryResult} from "../interfaces/IGameQueryResult";
import {withRequestsCache} from "@ngneat/elf-requests";
import {tap} from "rxjs/operators";
import {IApiResponseBase} from "../interfaces/IApiResponseBase";
import {IUpdateSensitiveGameSettings} from "../interfaces/IUpdateSensitiveGameSettings";
import {IUpdateGameSettings} from "../interfaces/IUpdateGameSettings";
import {IRecalculateGameEntriesResult} from "../interfaces/IRecalculateGameEntriesResult";
import {IRecalculateGameEntriesCommand} from "../interfaces/IRecalculateGameEntriesCommand";
import {IGroupedRecalculationEntries} from "../interfaces/IGroupedRecalculationEntries";
import {IPaymentTier} from "../interfaces/player/IPaymentTier";
import {GameStateEnum} from "../enum/GameStateEnum";
import {IAddPaymentTiersToGame} from "../interfaces/IAddPaymentTiersToGame";
import {IAddNewPaymentTier} from "../interfaces/IAddNewPaymentTier";
import {IGameLotteryLicence} from "../interfaces/IGameLotteryLicence";
import {IAddLotteryLicenceNumber} from "../interfaces/IAddLotteryLicenceNumber";
import {IEditLotteryLicenceNumber} from "../interfaces/IEditLotteryLicenceNumber";

@Injectable({
  providedIn: 'root'
})
export class LotteryLicenceService {

  private licencesStore = createStore(
    {name: 'all-game-licences'},
    withEntities<IGameLotteryLicence, 'Id'>({idKey: 'Id'}),
    withRequestsCache<'all-games'>()
  );

  constructor(private apiService: APIService) {
  }

  public selectLotteryLicences$(): Observable<IGameLotteryLicence[]> {
    return this.licencesStore.pipe(selectAllEntities());
  }

  public fetchLotteryLicences(gameIdP: string): Observable<IGameLotteryLicence[]> {
    return this.apiService.MakeGetRequest<IGameLotteryLicence[]>(`game/lottery-licences/${gameIdP}`).pipe(tap((res: IGameLotteryLicence[]) => {
      this.licencesStore.update(setEntities(res));
    }));
  }

  public addLotteryLicence(lotteryLicenceP: IAddLotteryLicenceNumber) {
    return this.apiService.MakePostRequest(`game/lottery-licence`, lotteryLicenceP).pipe(concatMap(() => {
      return this.fetchLotteryLicences(lotteryLicenceP.gameId);
    }));
  }

  public editLotteryLicence(lotteryLicenceP: IEditLotteryLicenceNumber) {
    return this.apiService.MakePutRequest(`game/lottery-licence`, lotteryLicenceP).pipe(concatMap(() => {
      return this.fetchLotteryLicences(lotteryLicenceP.gameId);
    }));
  }
}
