import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {IGameQueryResult} from "../interfaces/IGameQueryResult";
import {Injectable} from "@angular/core";
import {GameService} from "../services/game.service";
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class GetGamesForUserResolver implements Resolve<IGameQueryResult[]> {
  constructor(private gameService: GameService) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<IGameQueryResult[]> {
    return this.gameService.getGamesForUser();
  }
}
