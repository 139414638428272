import {APIService} from "./api.service";
import {map, Observable, tap} from "rxjs";
import {Injectable} from "@angular/core";
import {IAdminAuditEvent} from "../interfaces/audit/IAdminAuditEvent";
import {createStore, select, setProp, withProps} from "@ngneat/elf";
import {getAllEntities, setEntities, withEntities} from "@ngneat/elf-entities";
import {IPlayerAuditEvent} from "../interfaces/audit/IPlayerAuditEvent";
import {IAuditEvent} from "../interfaces/audit/IAuditEvent";
import {IAuditEventType} from "../interfaces/audit/IAuditEventTypes";
import {IAdminAuditFilter} from "../interfaces/audit/IAdminAuditFilter";
import {HttpParams} from "@angular/common/http";
import {IPlayerAuditFilter} from "../interfaces/audit/IPlayerAuditFilter";
import {IPlayerAuditPagedResponse} from "../interfaces/audit/IPlayerAuditPagedResponse";
import {IPaginationFilter} from "../interfaces/IPaginationFilter";
import {IAdminAuditPagedResponse} from "../interfaces/audit/IAdminAuditPagedResponse";
import {IPagedResponse} from "../interfaces/audit/IPagedResponse";
import {ISystemAuditPagedResponse} from "../interfaces/audit/ISystemAuditPagedResponse";

@Injectable({
  providedIn: 'root'
})
export class AuditingService {

  private defaultAuditRes: IPagedResponse = {
    Data: [],
    Errors: [],
    FirstPage: 1,
    LastPage: 1,
    Message: '',
    NextPage: '',
    PageNumber: 1,
    PageSize: 1,
    PreviousPage: '',
    Succeeded: false,
    TotalPages: 1,
    TotalRecords: 0
  }
  private playerAuditEventStore = createStore(
    {name: 'player-audit-reponse'},
    withProps<{ playerAuditEventResponse: IPlayerAuditPagedResponse }>({playerAuditEventResponse: this.defaultAuditRes})
  );

  private adminAuditEventStore = createStore(
    {name: 'admin-audit-store'},
    withProps<{ adminAuditEventResponse: IAdminAuditPagedResponse }>({adminAuditEventResponse: this.defaultAuditRes})
  );

  private systemAuditEventStore = createStore(
    {name: 'system-audit-store'},
    withProps<{ systemAuditEventResponse: ISystemAuditPagedResponse }>({systemAuditEventResponse: this.defaultAuditRes})
  );

  private auditEventTypeStore = createStore(
    {name: 'audit-event-type-store'},
    withEntities<IAuditEventType, 'Id'>({idKey: 'Id'})
  );

  public playerAudits$ = this.playerAuditEventStore.pipe(select(state => state.playerAuditEventResponse));
  public adminAudits$ = this.adminAuditEventStore.pipe(select(state => state.adminAuditEventResponse));
  public systemAudits$ = this.systemAuditEventStore.pipe(select(state => state.systemAuditEventResponse));

  constructor(private apiService: APIService) {
  }

  public retrievePlayerAudits(filterParams: IPlayerAuditFilter): Observable<IPlayerAuditEvent[]> {
    let queryParams: HttpParams = new HttpParams();

    if (filterParams) {
      if (filterParams.playerId) {
        queryParams = queryParams.append('playerId', filterParams.playerId)
      }

      queryParams = this.addDefaultQueryParams(queryParams, filterParams);
    }
    return this.apiService.MakeGetRequest<IPlayerAuditPagedResponse>(`audit/player`, queryParams).pipe(map((playerAuditsP) => {
      this.playerAuditEventStore.update(setProp('playerAuditEventResponse', playerAuditsP));
      return playerAuditsP.Data;
    }));
  }

  public retrieveMetaDataForAuditEvent(eventSourceP: number, auditId: string, gameId: string) {
    let queryParams: HttpParams = new HttpParams();
    queryParams = queryParams.append('sourceType', eventSourceP)
    queryParams = queryParams.append('id', auditId)
    queryParams = queryParams.append('gameId', gameId)
    return this.apiService.MakeGetRequest<string>(`audit/meta-data`, queryParams).pipe(map((val) => {
      return JSON.parse(val);
    }));
  };

  public retrieveAdminAudits(filterParams?: IAdminAuditFilter): Observable<IAdminAuditEvent[]> {
    let queryParams: HttpParams = new HttpParams();

    if (filterParams) {
      if (filterParams.adminId) {
        queryParams = queryParams.append('adminId', filterParams.adminId)
      }

      queryParams = this.addDefaultQueryParams(queryParams, filterParams);
    }

    return this.apiService.MakeGetRequest<IAdminAuditPagedResponse>(`audit/admin`, queryParams).pipe(map((adminAuditsP) => {
      this.adminAuditEventStore.update(setProp('adminAuditEventResponse', adminAuditsP));
      return adminAuditsP.Data;
    }));
  }

  private addDefaultQueryParams(paramsP: HttpParams, filterP: IPaginationFilter): HttpParams {
    if (filterP.eventTypeValue) {
      paramsP = paramsP.append('eventTypeValue', filterP.eventTypeValue)
    }
    if (filterP.pageNumber) {
      paramsP = paramsP.append('pageNumber', filterP.pageNumber)
    }
    if (filterP.pageSize) {
      paramsP = paramsP.append('pageSize', filterP.pageSize)
    }
    if (filterP.fromDate) {
      paramsP = paramsP.append('fromDate', filterP.fromDate.toDateString())
    }
    if (filterP.toDate) {
      paramsP = paramsP.append('toDate', filterP.toDate.toDateString())
    }
    if (filterP.gameId) {
      paramsP = paramsP.append('gameId', filterP.gameId)
    }
    if (filterP.genericContentSearch) {
      paramsP = paramsP.append('genericContentSearch', filterP.genericContentSearch);
    }
    if (filterP.isSuccess != null) {
      paramsP = paramsP.append('isSuccess', filterP.isSuccess);
    }
    return paramsP;
  }

  public retrieveSystemAudits(filterParams: IPaginationFilter): Observable<IAuditEvent[]> {

    let queryParams: HttpParams = new HttpParams();
    queryParams = this.addDefaultQueryParams(queryParams, filterParams);

    return this.apiService.MakeGetRequest<ISystemAuditPagedResponse>(`audit/system`, queryParams).pipe(map((systemAuditsP) => {
      this.systemAuditEventStore.update(setProp('systemAuditEventResponse', systemAuditsP));
      return systemAuditsP.Data;
    }));
  }

  public retrieveAllEventTypes(gameIdP: string): Observable<IAuditEventType[]> {
    return this.apiService.MakeGetRequest<IAuditEventType[]>(`audit/audit-event-types/${gameIdP}`).pipe(tap((auditEventTypesP) => {
      this.auditEventTypeStore.update(setEntities(auditEventTypesP));
    }));
  }

  public getAuditEventTypes(): IAuditEventType[] {
    return this.auditEventTypeStore.query(getAllEntities());
  }

}
