import {Injectable} from "@angular/core";
import {DatePipe} from "@angular/common";
import {ColDef} from "ag-grid-community";
import {FormatCurrencyPipe} from "../../pipes/format-currency.pipe";
import {PlayerNoteReasonType} from "../../enum/PlayerNoteReasonType";
import {PlayerSuspensionReasonTypes} from "../../enum/PlayerSuspensionReasonTypes";

@Injectable({
  providedIn: 'root'
})
export class PlayerInfoTableDefs {

  public transactionColumnDefs: ColDef[] = [
    {
      headerName: 'Charge Id',
      width: 250,
      sortable: true,
      field: 'StripeChargeId',
      resizable: true
    },
    {
      headerName: 'Total ($)',
      resizable: true,
      width: 95,
      field: 'Total',
      sortable: true,
      valueFormatter: params => {
        const convertedDate = this.formatCurrencyPipe.transform(params.value);
        return convertedDate ? convertedDate : '';
      }
    },
    {
      headerName: 'Transaction Date',
      resizable: true,
      sortable: true,
      width: 150,
      field: 'CreatedOn',
      valueFormatter: params => {
        const convertedDate = this.datePipe.transform(params.value, 'MMM d, y HH:mm');
        return convertedDate ? convertedDate : '';
      }
    },
    {
      headerName: 'Ticket Numbers',
      resizable: true,
      sortable: true,
      width: 120,
      field: 'TicketNumbers',
      valueFormatter: params => {
        return params.value.toString();
      }
    },
    {
      headerName: 'Payment Tier',
      field: 'PaymentTierString',
      resizable: true,
      sortable: true
    },
    {
      headerName: 'Draw Date',
      resizable: true,
      sortable: true,
      width: 250,
      valueFormatter: params => {
        const convertedEndDate = this.datePipe.transform(params.data.GameInstanceEndedOn, 'MMM d, y');
        if (convertedEndDate) {
          return convertedEndDate;
        }
        return '';
      }
    },
  ];

  public failedAutoplayColumnDefs: ColDef[] = [
    {
      headerName: 'Autoplay Group Id',
      field: 'AutoplayGroupId',
      resizable: true,
      sortable: true
    },
    {
      headerName: 'Payment Tier',
      field: 'PaymentTierString',
      resizable: true,
      sortable: true
    },
    {
      headerName: 'Numbers',
      resizable: true,
      sortable: true,
      field: 'TicketNumbers'
    }
  ];

  public returnAllNumberColumnDefs(includeGoldRushStrip: boolean): ColDef[] {
    const allNumbersColDef = [];
    if (includeGoldRushStrip) {
      allNumbersColDef.push({
        headerName: 'Gold Rush Strip #',
        field: 'GoldRushStripNumber',
        resizable: true,
        sortable: true
      })
    }
    return this.allNumbersColumnDefs.concat(allNumbersColDef);
  }

  public allNumbersColumnDefs: ColDef[] = [
    {
      headerName: 'Player number Id',
      field: 'Id',
      resizable: true,
      sortable: true
    },
    {
      headerName: 'Ticket #',
      field: 'TicketNumber',
      width: 100,
      resizable: true,
      sortable: true
    },
    {
      headerName: 'Created On',
      resizable: true,
      sortable: true,
      width: 250,
      field: 'CreatedOn',
      valueFormatter: params => {
        const convertedDate = this.datePipe.transform(params.value, 'MMM d, y HH:mm');
        return convertedDate ? convertedDate : '';
      }
    },
    {
      headerName: 'Active',
      resizable: true,
      sortable: true,
      field: 'Active',
      width: 80,
      valueFormatter: params => {
        return params.value ? 'Yes' : 'No'
      }
    }
  ];

  public playedNumberGroupsColumnDefs: ColDef[] = [
    {
      headerName: 'Player Payment Payment Tier Id',
      field: 'Id',
      resizable: true,
      sortable: true
    },
    {
      headerName: 'Payment Tier',
      field: 'PaymentTierString',
      resizable: true,
      sortable: true
    },
    {
      headerName: 'Played',
      resizable: true,
      sortable: true,
      field: 'Played',
      valueFormatter: params => {
        return params.value ? 'Yes' : 'No'
      }
    },
    {
      headerName: 'Played Via Autoplay',
      resizable: true,
      sortable: true,
      field: 'Autoplay',
      valueFormatter: params => {
        return params.value ? 'Yes' : 'No'
      }
    },
    {
      headerName: 'Numbers',
      resizable: true,
      sortable: true,
      field: 'TicketNumbers'
    }
  ];

  public playerNotesColumnDefs: ColDef[] = [
    {
      headerName: 'Player Note Id',
      field: 'Id',
      resizable: true,
      sortable: true,
    },
    {
      headerName: "Player Id",
      field: "PlayerId",
      resizable: true,
      sortable: true,
    },
    {
      headerName: "Message",
      field: "Message",
      resizable: true,
      sortable: true
    },
    {
      headerName: "Created On",
      field: "CreatedOn",
      resizable: true,
      sortable: true,
      valueFormatter: params => {
        const convertedDate = this.datePipe.transform(params.value, 'MMM d, y HH:mm');
        return convertedDate ? convertedDate : '';
      }
    },
    {
      headerName: "Reason Type",
      field: "ReasonType",
      resizable: true,
      sortable: true,
      valueFormatter: params => {
        return PlayerNoteReasonType[params.value];
      }
    },
    {
      headerName: "Last Updated By",
      field: "LastUpdatedBy",
      resizable: true,
      sortable: true
    },
    {
      headerName: "Causable Admin Note",
      field: "IsCausableAdminNote",
      resizable: true,
      sortable: true,
      valueFormatter: params => {
        return params.value ? "Yes" : "No"
      }
    }
  ];

  public playerSuspensionColumnDefs: ColDef[] = [
    {
      headerName: "Player Suspension Id",
      field: "Id",
      resizable: true,
      sortable: true
    },
    {
      headerName: "Player Id",
      field: "PlayerId",
      resizable: true,
      sortable: true
    },
    {
      headerName: "Suspension Reason Type",
      field: "PlayerSuspensionReasonType",
      resizable: true,
      sortable: true,
      valueFormatter: params => {
        return PlayerSuspensionReasonTypes[params.value]
      }
    },
    {
      headerName: "Created On",
      field: "CreatedOn",
      resizable: true,
      sortable: true,
      valueFormatter: params => {
        const convertedDate = this.datePipe.transform(params.value, 'MMM d, y HH:mm');
        return convertedDate ? convertedDate : '';
      }
    },
    {
      headerName: "Resolved",
      field: "Resolved",
      resizable: true,
      sortable: true,
      valueFormatter: params => {
        return params.value ? "Yes" : "No"
      }
    },
    {
      headerName: "Resolved On",
      field: "ResolvedOn",
      resizable: true,
      sortable: true,
      valueFormatter: params => {
        //DateTime.Min check
        if (params.value == "0001-01-01T00:00:00Z"){
          return "";
        }
        const convertedDate = this.datePipe.transform(params.value, 'MMM d, y HH:mm');
        return convertedDate!;
      }
    }
  ]

  constructor(private datePipe: DatePipe,
              private formatCurrencyPipe: FormatCurrencyPipe) {
  }

}
