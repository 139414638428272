import {Injectable} from "@angular/core";
import {createStore} from "@ngneat/elf";
import {selectAllEntities, setEntities, withEntities} from "@ngneat/elf-entities";
import {withRequestsCache} from "@ngneat/elf-requests";
import {APIService} from "./api.service";
import {concatMap, Observable} from "rxjs";
import {tap} from "rxjs/operators";
import {IFrequentlyAskedQuestions} from "../interfaces/IFrequentlyAskedQuestions";
import {IAddFrequentlyAskedQuestions} from "../interfaces/IAddFrequentlyAskedQuestions";
import {IEditFrequentlyAskedQuestions} from "../interfaces/IEditFrequentlyAskedQuestions";

@Injectable({
  providedIn: 'root'
})
export class FrequentlyAskedQuestionsService {
  private faqsStore = createStore(
    {name: 'all-faqs'},
    withEntities<IFrequentlyAskedQuestions, 'Id'>({idKey: 'Id'}),
    withRequestsCache<'all-games'>()
  );

  constructor(private apiService: APIService) {
  }

  public selectFaqs$(): Observable<IFrequentlyAskedQuestions[]> {
    return this.faqsStore.pipe(selectAllEntities());
  }

  public fetchFaqs(): Observable<IFrequentlyAskedQuestions[]> {
    return this.apiService.MakeGetRequest<IFrequentlyAskedQuestions[]>(`causable/faqs`).pipe(tap((res: IFrequentlyAskedQuestions[]) => {
      this.faqsStore.update(setEntities(res));
    }));
  }

  public addFaq(lotteryLicenceP: IAddFrequentlyAskedQuestions) {
    return this.apiService.MakePostRequest(`causable/faq`, lotteryLicenceP).pipe(concatMap(() => {
      return this.fetchFaqs();
    }));
  }

  public editFaq(faqP: IEditFrequentlyAskedQuestions) {
    return this.apiService.MakePutRequest(`causable/faq`, faqP).pipe(concatMap(() => {
      return this.fetchFaqs();
    }));
  }
}
