import {Component} from '@angular/core';
import {UIStateEnum} from "../../../enum/UIStateEnum";
import {concatMap} from "rxjs";
import {PlayersService} from "../../../services/players.service";
import {ErrorHandlingService} from "../../../services/error-handling.service";
import {GameService} from "../../../services/game.service";
import {IPlayerNumbersForGameQueryResult} from "../../../interfaces/player/IPlayerNumbersForGameQueryResult";
import {IFailedAutoplayGroupRow} from "../../../interfaces/player/IFailedAutoplayGroupRow";
import {IPlayerNumberQueryResult} from "../../../interfaces/player/IPlayerNumberQueryResult";
import {IPlayedPlayerNumbersByGroupRow} from "../../../interfaces/player/IPlayedPlayerNumbersByGroupRow";
import {IFailedAutoplayGroup} from "../../../interfaces/player/IFailedAutoplayGroup";
import {IGameInstanceScopedPlayerNumberGroup} from "../../../interfaces/player/IGameInstanceScopedPlayerNumberGroup";
import {IPaymentTier} from "../../../interfaces/player/IPaymentTier";
import {IPlayerNumberWithAutoplayFlag} from "../../../interfaces/player/IPlayerNumberWithAutoplayFlag";
import {FormatCurrencyPipe} from "../../../pipes/format-currency.pipe";
import {
  DeactivatePlayerNumbersComponent
} from "../../dialogs/deactivate-player-numbers/deactivate-player-numbers.component";
import {ActivatePlayerNumbersComponent} from "../../dialogs/activate-player-numbers/activate-player-numbers.component";
import {ColDef, GridApi, GridReadyEvent} from "ag-grid-community";
import {PlayerInfoTableDefs} from "../player-info-table-defs";
import {MatDialog} from "@angular/material/dialog";
import {MatButton} from "@angular/material/button";
import {AgGridAngular} from "ag-grid-angular";
import {MatIcon} from "@angular/material/icon";
import {GameTypeEnum} from "../../../enum/GameTypeEnum";

@Component({
  selector: 'app-player-numbers-tab',
  standalone: true,
  templateUrl: './player-numbers-tab.component.html',
  imports: [
    MatButton,
    MatIcon,
    AgGridAngular
  ],
  styleUrl: './player-numbers-tab.component.scss'
})
export class PlayerNumbersTabComponent {

  public uiState: UIStateEnum = UIStateEnum.ShowData;
  public uiStateForTemplate = UIStateEnum;
  public chosenPlayer = this.playerService.getActivePlayer();
  public activeGame = this.gameService.activeGame();
  public failedAutoplayGroups: IFailedAutoplayGroupRow[] = [];
  public allPlayerNumbers: IPlayerNumberQueryResult[] = [];
  public availableTiersPerGame = this.gameService.getActiveGamePaymentTiers();
  public playedPlayerNumberGroupRows: IPlayedPlayerNumbersByGroupRow[] = [];

  public unplayedNumbersGridApi!: GridApi<IPlayerNumberQueryResult>;
  public playedNumbersGridApi!: GridApi<IPlayedPlayerNumbersByGroupRow>;
  public failedAutoplayGroupGridApi!: GridApi<IFailedAutoplayGroupRow>;

  public playedNumberGroupsColumnDefs: ColDef[] = this.playerInfoTables.playedNumberGroupsColumnDefs;
  public allNumbersColumnDefs: ColDef[] = this.playerInfoTables.returnAllNumberColumnDefs(this.gameService.activeGame().Type === GameTypeEnum.GoldRush);
  public failedColumnDefs: ColDef[] = this.playerInfoTables.failedAutoplayColumnDefs;

  constructor(private playerService: PlayersService,
              private errorHandlingService: ErrorHandlingService,
              private gameService: GameService,
              private formatCurrencyPipe: FormatCurrencyPipe,
              private playerInfoTables: PlayerInfoTableDefs,
              private matDialog: MatDialog) {
    this.getTicketNumbers();
  }

  getTicketNumbers() {
    this.uiState = UIStateEnum.ShowLoading;
    // when subject gets pushed a value (will happen on de-active ticket numbers) get updated ticket data from api.
    this.playerService.fetchUpdatedTicketNumbersSubject$
      .pipe(
        concatMap(() => this.playerService.getPlayerNumbersByGameId(this.chosenPlayer?.Id!, this.activeGame?.Id!))
      ).subscribe({
      next: (allNumberDataP) => {
        this.setPlayerNumbers(allNumberDataP);
      },
      error: (err) => {
        this.errorHandlingService.displayPageLevelErrorMessage(err, 'Looks there was an issue retrieving player tickets.' +
          ' Please try reloading and if the problem persists contact a system administrator');
      }
    });
  }

  private setPlayerNumbers(allNumberDataP: IPlayerNumbersForGameQueryResult) {
    // map payment tiers
    if (this.availableTiersPerGame.length > 0) {

      allNumberDataP.FailedAutoplayGroups = allNumberDataP.FailedAutoplayGroups.map((failedGroup) => {
        failedGroup.PaymentTier = this.availableTiersPerGame.find((tier) => tier.Id === failedGroup.PaymentTierId);
        return failedGroup;
      });

      allNumberDataP.PlayedPlayerNumberGroups = allNumberDataP.PlayedPlayerNumberGroups
        .map((numberGroup) => {
          numberGroup.PaymentTier = this.availableTiersPerGame.find((tier) => tier.Id === numberGroup.PaymentTierId);
          return numberGroup;
        });

      this.playedPlayerNumberGroupRows = this.formatPurchasedPlayerNumberGroups(allNumberDataP.PlayedPlayerNumberGroups);
      this.failedAutoplayGroups = this.formatFailedAutoplayNumberGroups(allNumberDataP.FailedAutoplayGroups);
      this.allPlayerNumbers = allNumberDataP.AllPlayerNumbers;
      this.uiState = UIStateEnum.ShowData;
    }
  }

  formatFailedAutoplayNumberGroups(groupedNumbersP: IFailedAutoplayGroup[]) {
    let numberGroupRows: IFailedAutoplayGroupRow[] = [];
    groupedNumbersP.forEach(group => {
      numberGroupRows.push({
        TicketNumbers: this.getTicketNumbersFromListOfTickets(group.PlayerNumbers),
        PaymentTierString: this.formatTierToReadableName(group.PaymentTier!),
        AutoplayGroupId: group.AutoplayGroupId
      })
    });
    return numberGroupRows;
  }

  formatPurchasedPlayerNumberGroups(groupedNumbersP: IGameInstanceScopedPlayerNumberGroup[]) {
    let numberGroupRows: IPlayedPlayerNumbersByGroupRow[] = [];

    groupedNumbersP.forEach(group => {
      numberGroupRows.push({
        TicketNumbers: this.getTicketNumbersFromListOfTickets(group.PlayerNumbers),
        Played: group.Played,
        PaymentTierString: this.formatTierToReadableName(group.PaymentTier!),
        Autoplay: group.Autoplay,
        Id: group.Id
      })
    });
    return numberGroupRows;
  }

  formatTierToReadableName(tierP: IPaymentTier) {
    return `${tierP.NumberOfTickets} for ${this.formatCurrencyPipe.transform(tierP.Price)}`;
  }

  getTicketNumbersFromListOfTickets(ticketsP: IPlayerNumberWithAutoplayFlag[]) {
    let groupTicketNumbers: number[] = ticketsP.map((ticket) => ticket.TicketNumber);
    return groupTicketNumbers.toString();
  }

  onPlayedGridReady(params: GridReadyEvent<IPlayedPlayerNumbersByGroupRow>) {
    this.playedNumbersGridApi = params.api;
    this.playedNumbersGridApi.sizeColumnsToFit({
      defaultMinWidth: 100,
    });
  }

  onFailedAutoplayGridReady(params: GridReadyEvent<IFailedAutoplayGroupRow>) {
    this.failedAutoplayGroupGridApi = params.api;
    this.failedAutoplayGroupGridApi.sizeColumnsToFit({
      defaultMinWidth: 100,
    });
  }

  onUnplayedGridReady(params: GridReadyEvent<IPlayerNumberQueryResult>) {
    this.unplayedNumbersGridApi = params.api;
    this.unplayedNumbersGridApi.sizeColumnsToFit({
      defaultMinWidth: 100,
    });
  }

  public onDeactivatePlayerNumbersClick() {
    const dialogRef = this.matDialog.open(DeactivatePlayerNumbersComponent, {
      width: '650px',
      data: {
        PlayerId: this.chosenPlayer?.Id,
        GameId: this.activeGame?.Id,
        AllPlayerTickets: this.allPlayerNumbers
      }
    });
    dialogRef.afterClosed().subscribe(
      {
        next: (updateSuccessful: boolean) => {
          if (updateSuccessful) {
            this.playerService.fetchUpdatedTicketNumbersSubject.next(true);
          }
        }
      })
  }

  public onActivatePlayerNumbersClick() {
    const dialogRef = this.matDialog.open(ActivatePlayerNumbersComponent, {
      width: '650px',
      data: {
        PlayerId: this.chosenPlayer?.Id,
        GameId: this.activeGame?.Id,
        AllPlayerTickets: this.allPlayerNumbers
      }
    });
    dialogRef.afterClosed().subscribe(
      {
        next: (updateSuccessful: boolean) => {
          if (updateSuccessful) {
            this.playerService.fetchUpdatedTicketNumbersSubject.next(true);
          }
        }
      })
  }
}
