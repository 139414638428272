import {Component, Inject} from '@angular/core';
import {UIStateEnum} from "../../../enum/UIStateEnum";
import {FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators} from "@angular/forms";
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogTitle
} from "@angular/material/dialog";
import {ICharityPaymentBalance} from "../../../interfaces/reporting/ICharityPaymentBalance";
import {MatFormField} from "@angular/material/form-field";
import {MatSelectModule} from "@angular/material/select";
import {PipesModule} from "../../../pipes/pipes.module";
import {MatDatepicker, MatDatepickerModule} from "@angular/material/datepicker";
import {MatInput} from "@angular/material/input";
import {MatProgressSpinner} from "@angular/material/progress-spinner";
import {MatButton} from "@angular/material/button";

@Component({
  selector: 'app-charity-payouts-dialog',
  standalone: true,
  templateUrl: './make-payment-dialog.component.html',
  imports: [
    MatDialogTitle,
    MatDialogContent,
    MatFormField,
    MatSelectModule,
    FormsModule,
    ReactiveFormsModule,
    PipesModule,
    MatDatepickerModule,
    MatDatepicker,
    MatInput,
    MatProgressSpinner,
    MatDialogActions,
    MatDialogClose,
    MatButton
  ],
  styleUrls: ['./make-payment-dialog.component.scss']
})
export class MakePaymentDialogComponent {

  public uiState: UIStateEnum = UIStateEnum.ShowData;
  public uiStateEnumForTemplate = UIStateEnum;
  public selectedCharityBalance!: ICharityPaymentBalance;

  public makeCharityPaymentForm = new FormGroup({
    paymentAmount: new FormControl<number | null>(0, Validators.required),
    paymentDateControl: new FormControl<Date>(new Date(), Validators.required),
  });

  constructor(@Inject(MAT_DIALOG_DATA) public charityBreakdown: ICharityPaymentBalance[]) {
  }

  updateForm() {
    this.makeCharityPaymentForm.get('paymentAmount')?.setValue(this.selectedCharityBalance.TotalOwedToCharity / 100);
  }

}
