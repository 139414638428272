<app-dialog-base [showLoadingSpinner]="uiState === uiStateEnumForTemplate.ShowLoading">
  <span dialog-title>Send Email Blast</span>

  <ng-container dialog-content *ngIf="uiState === uiStateEnumForTemplate.ShowData">
    <form [formGroup]="emailForm">
      <mat-form-field appearance="outline" class="full-width">
        <mat-label>Subject</mat-label>
        <input matInput formControlName="subject" placeholder="Subject" data-testid="email-subject-input">
        <mat-hint>Note that the game name will be prepended to the subject</mat-hint>
        <mat-error *ngIf="subjectControl.errors">Subject is required</mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline" class="full-width">
        <mat-label>Message</mat-label>
        <textarea matInput
                  formControlName="message"
                  placeholder="Message"
                  data-testid="email-message-input"></textarea>
        <mat-hint>The message you wish to send to all users in the game</mat-hint>
        <mat-error *ngIf="messageControl.errors">Message is required.</mat-error>
      </mat-form-field>
    </form>
  </ng-container>

  <ng-container actions>
    <button mat-button
            (click)="sendEmailBlast()"
            data-testid="send-email-blast-button"
            [disabled]="uiState === uiStateEnumForTemplate.ShowLoading">
      Send
    </button>
  </ng-container>
</app-dialog-base>
