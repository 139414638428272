import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {GameService} from "../services/game.service";
import {map} from "rxjs";
import {catchError} from "rxjs/operators";
import {ErrorHandlingService} from "../services/error-handling.service";

@Injectable({
  providedIn: 'root'
})
export class GetAllGamesForUserGuard implements CanActivate {
  constructor(private gameService: GameService,
              private errorHandlingService: ErrorHandlingService,
              private router: Router) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.gameService.getGamesForUser().pipe(map((gamesForUserP) => {
      // if you only have access to one org, check if that org only have one game
      if (gamesForUserP.length == 1) {
        const chosenGameId = gamesForUserP[0].Id;
        this.router.navigateByUrl(`${chosenGameId}/active-game-instances`);
        return false;
      }

      return true;
    }), catchError((err) => {
      this.errorHandlingService.displayPageLevelErrorMessage(err);
      throw err;
    }))
  }
}
