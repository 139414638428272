@if (activeGameInstance$ | async; as activeGameInstance) {
  <div class="one-column-page-container">
    <h2 data-testid="active-game-title">Current {{ activeGame?.Name }} Draw
      <button
        mat-mini-fab
        data-testid="open-manage-game-options-menu-button"
        *userHasCausableAdminAccess
        class="float-right primary-background-color"
        [matMenuTriggerFor]="menu">
        <mat-icon matTooltipPosition="right"
                  matTooltip="open game settings"
        >more_vertical
        </mat-icon>
      </button>
    </h2>

    @if (activeGameInstance.Id) {
      <div class="flex-row-wrap gap-normal justify-space-between">
        <div class="full-width">
          <app-display-active-game-instance
            [setSelectedGameInstance]="activeGameInstance"></app-display-active-game-instance>
        </div>
      </div>
    }

  </div>
} @else {

  <div class="flex-row-wrap gap-normal" data-testid="no-active-games-div">
    Hey looks like there is no currently active draw. Please wait while the new game is being set up.
  </div>
}

<mat-menu #menu="matMenu">
  <button mat-menu-item
          (click)="openEndGameInstance()"
          data-testid="edit-category-name-option">
    <mat-icon class="dark-grey menu-icon">edit</mat-icon>
    <span class="dark-grey menu-text">End Game Instance</span>
  </button>
  <button mat-menu-item *hasPermission="PermissionTypes.SendEmailBlast"
          (click)="openEmailBlast()"
          data-testid="send-message-to-all-users">
    <mat-icon class="dark-grey menu-icon">email</mat-icon>
    <span class="dark-grey menu-text">Send Message to All Users</span>
  </button>
</mat-menu>
