<h2 mat-dialog-title>Make Charity Payments</h2>
<mat-dialog-content class="mat-typography">
  <mat-form-field appearance="outline" class="full-width">
    <mat-label>Choose Charity</mat-label>
    <mat-select [(ngModel)]="selectedCharityBalance" (ngModelChange)="updateForm()" data-testid="choose-charity-select">
      @for (charity of charityBreakdown; track charity) {
        <mat-option [value]="charity" data-testid="choose-charity-select-option">
          {{ charity.CharityName }}
        </mat-option>
      }
    </mat-select>
  </mat-form-field>

  @if(selectedCharityBalance) {
    <form [formGroup]="makeCharityPaymentForm">

      <div class="margin-bottom-med text-center charity-balance">
        <div>{{ selectedCharityBalance.CharityName }} Balance:</div>
        <div class="bold-text margin-top-med">{{ selectedCharityBalance.TotalOwedToCharity | formatCurrency }}</div>
      </div>

      <mat-form-field appearance="outline">
        <mat-label>Choose a date</mat-label>
        <input matInput formControlName="paymentDateControl" [matDatepicker]="picker" data-testid="payment-date-input">
        <mat-hint>MM/DD/YYYY</mat-hint>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>
      <mat-form-field appearance="outline" class="full-width margin-top-med">
        <mat-label>Enter Payment Amount ($)</mat-label>
        <input
          formControlName="paymentAmount"
          type="number"
          matInput
          placeholder="enter payment amount"
          data-testid="payment-amount-input">
      </mat-form-field>
    </form>
  }

  @if(uiState === uiStateEnumForTemplate.ShowLoading) {
    <mat-spinner></mat-spinner>
  }
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button [mat-dialog-close]="false" data-testid="cancel-button">Cancel</button>
  <button mat-button class="primary-button" [disabled]="!makeCharityPaymentForm.valid"
          data-testid="make-payment-button">Make Payment
  </button>
</mat-dialog-actions>
