import { Injectable } from '@angular/core';
import {APIService} from "./api.service";
import {IUpdateGameRulesCommand} from "../interfaces/IUpdateGameRulesCommand";
import {IGameRules} from "../interfaces/IGameRules";

@Injectable({
  providedIn: 'root'
})
export class GameRulesService {

  constructor(private apiService: APIService) { }

  getGameRules(gameIdP: string) {
    return this.apiService.MakeGetRequest<IGameRules>("game/game-rules/" + gameIdP);
  }

  updateGameRules(gameTos: IUpdateGameRulesCommand) {
    return this.apiService.MakePutRequest<IGameRules>("game/game-rules", gameTos);
  }
}
