import {Component} from '@angular/core';
import {IPlayerNote} from "../../../interfaces/player/IPlayerNote";
import {UIStateEnum} from "../../../enum/UIStateEnum";
import {ColDef, GridApi, GridReadyEvent} from "ag-grid-community";
import {PlayersService} from "../../../services/players.service";
import {GameService} from "../../../services/game.service";
import {ActiveUserService} from "../../../services/active-user.service";
import {PlayerInfoTableDefs} from "../player-info-table-defs";
import {AddPlayerGameNoteComponent} from "../../dialogs/add-player-game-note/add-player-game-note.component";
import {MatDialog} from "@angular/material/dialog";
import {ErrorHandlingService} from "../../../services/error-handling.service";
import {MatButton} from "@angular/material/button";
import {AgGridAngular} from "ag-grid-angular";
import {MatIcon} from "@angular/material/icon";

@Component({
  selector: 'app-player-game-notes-tab',
  standalone: true,
  templateUrl: './player-game-notes-tab.component.html',
  imports: [
    MatButton,
    MatIcon,
    AgGridAngular
  ],
  styleUrl: './player-game-notes-tab.component.scss'
})
export class PlayerGameNotesTabComponent {

  public playerNotes: IPlayerNote[] = [];
  public uiState: UIStateEnum = UIStateEnum.ShowData;
  public uiStateForTemplate = UIStateEnum;
  public playerNotesColumnDefs: ColDef[] = this.playerInfoTables.playerNotesColumnDefs;
  public playerNotesApi!: GridApi<IPlayerNote>;
  public activePlayer = this.playerService.getActivePlayer();

  constructor(private playerService: PlayersService,
              private gameService: GameService,
              private activeUserService: ActiveUserService,
              private errorHandlingService: ErrorHandlingService,
              private playerInfoTables: PlayerInfoTableDefs,
              private matDialog: MatDialog) {
    this.getPlayerNotes();
  }

  getPlayerNotes() {
    this.playerService.getPlayerGameInfo(this.activePlayer?.Id!, this.gameService.activeGame().Id).subscribe({
      next: res => {
        this.playerNotes = res.PlayerGameNotes;
      },
      error: (err) => {
        this.errorHandlingService.displayPageLevelErrorMessage(err);
      }
    });
  }

  onPlayerNotesGridReady(params: GridReadyEvent<IPlayerNote>) {
    this.playerNotesApi = params.api;
    this.playerNotesApi.setColumnVisible('IsCausableAdminNote', this.activeUserService.activeUser().CausableAdmin)
    this.playerNotesApi.sizeColumnsToFit({
      defaultMinWidth: 100,
    });
  }

  addPlayerNote() {
    const dialogRef = this.matDialog.open(AddPlayerGameNoteComponent, {
      width: '650px',
      //This is to make sure that the dialog opened is set to add a note to player, not update one
      data: undefined
    });
    dialogRef.afterClosed().subscribe({
      next: (addSuccessful: boolean) => {
        if (addSuccessful) {
          this.playerService.getPlayerGameInfo(this.activePlayer?.Id!, this.gameService.activeGame().Id).subscribe(
            () => this.getPlayerNotes());
        }
      }
    })
  }

  onPlayerNotesRowClicked(chosenNote: any) {
    const dialogRef = this.matDialog.open(AddPlayerGameNoteComponent,
      {
        width: '650px',
        data: chosenNote.data
      });
    dialogRef.afterClosed().subscribe({
      next: (updateSuccessful: boolean) => {
        if (updateSuccessful) {
          this.playerService.getPlayerGameInfo(this.activePlayer?.Id!, this.gameService.activeGame().Id).subscribe(
            () => this.getPlayerNotes());
        }
      }
    });
  }
}
