import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {GameService} from "../services/game.service";
import {map} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class GameSelectedGuard implements CanActivate {
  constructor(private gameService: GameService,
              private router: Router) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const gameIdFromRoute = route.paramMap.get('gameId');
    const activeGameInstate = this.gameService.activeGame()?.Id;

    // game is already set in state so continue with navigation
    if (activeGameInstate && gameIdFromRoute === activeGameInstate) {
      return true;
    }
    // the game is not in state
    if (gameIdFromRoute) {

      return this.gameService.getGameByGameId(gameIdFromRoute).pipe(
        map((gameP) => {
          if (gameP) {
            this.gameService.updateActiveGame(gameP);
            return true;
          }

          return false;
        })
      );
    }

    return this.router.navigateByUrl('choose-game');
  }
}
