@if (activeCharityCategory$ | async; as chosenCategory) {
  <div class="flex-row justify-space-between">
    <h2 data-testid="category-title">{{ chosenCategory.Category.Name }} - Category
      @if (!chosenCategory.Category.Active) {
        <span class="inactive"> (inactive)</span>
      }

      <button mat-icon-button
              data-testid="open-category-options-menu-button"
              id="charity-menu-button"
              *hasPermission="permissionTypes.ModifyCharities"
              [matMenuTriggerFor]="menu">
        <mat-icon matTooltipPosition="right"
                  matTooltip="open admin user menu"
        >more_vertical
        </mat-icon>
      </button>
    </h2>
  </div>
  <div class="margin-bottom-med">
    <button mat-raised-button (click)="onBackButtonClick(charityScreens.AllCategories)"
            data-testid="back-to-all-categories-button">
      <mat-icon>arrow_back_ios</mat-icon>
      Back
    </button>
  </div>

  <div cdkDropListGroup class="full-width" *hasPermission="permissionTypes.ModifyCharities">
    <div class="margin-top-med margin-bottom-med text-end">
      @if (chosenCategoryCharities.length > 1) {
        <button
          mat-stroked-button
          data-testid="sort-charities-button"
          [color]="enableSortCharities ? 'warn': 'primary'"
          (click)="updateSortingCharities()">
          {{ enableSortCharities ? 'Cancel' : 'Sort List' }}
          <mat-icon class="enable-sort">sort</mat-icon>
        </button>
      }

      @if (enableSortCharities) {
        <button
          mat-stroked-button
          matTooltip="Sort Charities Alphabetically"
          color="primary"
          data-testid="sort-charities-alphabetically-button"
          class="margin-left-med"
          (click)="sortCharitiesAlphabetically()">
          <mat-icon class="padding-left-small">sort_by_alpha</mat-icon>
        </button>
      }

      @if (enableSortCharities) {
        <button
          mat-raised-button
          color="primary"
          data-testid="sort-charities-save-changes-button"
          class="margin-left-med"
          (click)="submitCharityChanges()"
          matTooltip="Save changes to sort order">Save Changes
          <mat-icon>save</mat-icon>
        </button>
      }

    </div>
  </div>
  <div
    cdkDropList
    [cdkDropListData]="chosenCategoryCharities"
    class="charity-list"
    data-testid="charity-list"
    [class.drag-cursor]="enableSortCharities"
    [class.pointer-cursor]="!enableSortCharities"
    [cdkDropListDisabled]="!enableSortCharities"
    (cdkDropListDropped)="dropCharity($event)">

    @for (item of chosenCategoryCharities; track item) {
      <div
        class="drag-box"
        data-testid="charity-list-item"
        cdkDrag
        (click)="viewCharity(item)">
        <div class="padding-right-med flex-row justify-space-between align-center full-width">
          <div>
            {{ item.Name }} @if (!item.Active) {
            <span class="inactive"> (inactive)</span>
          }
          </div>
          <div class="action-section padding-right-med">
            <mat-icon
              class="sort-icon"
              color="primary"
            >{{ enableSortCharities ? 'drag_indicator' : 'arrow_forward_ios' }}
            </mat-icon>
          </div>
        </div>
      </div>
    }

  </div>

  <mat-menu #menu="matMenu">
    <button mat-menu-item
            (click)="openCategoryNameChangeDialog()"
            data-testid="edit-category-name-option">
      <mat-icon class="dark-grey menu-icon">edit</mat-icon>
      <span class="dark-grey menu-text">Edit Name</span>
    </button>
    @if (chosenCategory.Category.Active) {
      <button mat-menu-item
              (click)="onDeactivateCharityClick(chosenCategory)"
              data-testid="deactivate-category-option"
              [disabled]="categoryHasActiveCharities"
              [matTooltip]="categoryHasActiveCharities ? 'You cannot deactivate a category that has associated active charities. Please move the active charities or deactivate them' : ''">
        <mat-icon class="red-text">do_disturb</mat-icon>
        <span class="red-text menu-text">Deactivate</span>
      </button>
    } @else {
      <button mat-menu-item
              data-testid="reactivate-category-option"
              (click)="onReactivateCategoryClick(chosenCategory)">
        <mat-icon class="green-text">refresh</mat-icon>
        <span class="green-text menu-text">Reactivate</span>
      </button>
    }
  </mat-menu>

}
