import {Component} from '@angular/core';
import {FormControl, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {PermissionTypes} from "../../../enum/PermissionTypes";
import {IDateRanges} from "../../../interfaces/reporting/IDateRanges";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatDatepickerModule} from "@angular/material/datepicker";
import {MatButton} from "@angular/material/button";
import {MatTabsModule} from "@angular/material/tabs";
import {HasPermissionDirective} from "../../../directives/permissions/has-permission";
import {
  ComprehensiveReportByDateRangeComponent
} from "../comprehensive-report-by-date-range/comprehensive-report-by-date-range.component";
import {LotteryLicenseReportComponent} from "../lottery-license-report/lottery-license-report.component";

@Component({
  selector: 'app-date-range-reporting',
  standalone: true,
  templateUrl: './date-range-reporting.component.html',
  imports: [
    ReactiveFormsModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatButton,
    MatTabsModule,
    HasPermissionDirective,
    ComprehensiveReportByDateRangeComponent,
    LotteryLicenseReportComponent
  ],
  styleUrls: ['./date-range-reporting.component.scss', '../../../pages/reports/reports.scss',]
})
export class DateRangeReportingComponent {
  public comprehensiveDatesFromGroup = new FormGroup({
    startDate: new FormControl('', Validators.required),
    endDate: new FormControl('', Validators.required),
  });

  public permissionTypes = PermissionTypes;
  public dateRanges!: IDateRanges;
  public showReports = false;

  constructor() {
  }

  onSubmit() {
    this.dateRanges = {
      StartDate: this.comprehensiveDatesFromGroup.controls.startDate.value!,
      EndDate: this.comprehensiveDatesFromGroup.controls.endDate.value!
    }
    this.showReports = true;
  }
}
