import {Component, OnInit} from '@angular/core';
import {CharityService} from "../../services/charity.service";
import {GameService} from "../../services/game.service";
import {ICharityPaymentBalance} from "../../interfaces/reporting/ICharityPaymentBalance";
import {ColDef, GridApi, GridReadyEvent} from "ag-grid-community";
import {FormatCurrencyPipe} from "../../pipes/format-currency.pipe";
import {MatDialog,} from "@angular/material/dialog";
import {MakePaymentDialogComponent} from "../../components/dialogs/make-payment-dialog/make-payment-dialog.component";
import {
  CharityPayoutCharityInfoComponent
} from "../../components/cell-renderers/charity-payout-charity-info/charity-payout-charity-info.component";
import {GamePageBase} from "../../shared/game-page-base";
import {ErrorHandlingService} from "../../services/error-handling.service";
import {UIStateEnum} from "../../enum/UIStateEnum";
import {MatButton} from "@angular/material/button";
import {MatTooltip} from "@angular/material/tooltip";
import {AgGridAngular} from "ag-grid-angular";
import {LoadingCardContentComponent} from "../../shared/loading-card-content/loading-card-content.component";
import {MatIcon} from "@angular/material/icon";

@Component({
  selector: 'app-charity-payouts',
  standalone: true,
  templateUrl: './charity-payouts.component.html',
  imports: [
    MatButton,
    MatTooltip,
    MatIcon,
    AgGridAngular,
    LoadingCardContentComponent
  ],
  styleUrls: ['./charity-payouts.component.css']
})
export class CharityPayoutsComponent extends GamePageBase implements OnInit {

  public charityBreakdown: ICharityPaymentBalance[] = [];
  public charityBreakdownGridApi!: GridApi<ICharityPaymentBalance>;

  public charityBreakdownDefs: ColDef[] = [
    {
      headerName: 'Charity Name',
      cellRenderer: CharityPayoutCharityInfoComponent,
      resizable: true,
      sortable: true
    },
    {
      headerName: 'Total Contributed To Charity',
      resizable: true,
      wrapHeaderText: true,
      sortable: true,
      field: 'TotalContributions',
      valueFormatter: params => {
        return this.currencyPipe.transform(params.value);
      }
    },
    {
      headerName: 'Paid To Charity',
      resizable: true,
      sortable: true,
      valueFormatter: params => {
        return this.currencyPipe.transform(params.value);
      },
      width: 250,
      field: 'TotalPaidToCharity'
    },
    {
      headerName: 'Owed To Charity (Balance)',
      resizable: true,
      wrapHeaderText: true,
      valueFormatter: params => {
        return this.currencyPipe.transform(params.value);
      },
      sortable: true,
      field: 'TotalOwedToCharity'
    }
  ];

  constructor(private charityService: CharityService,
              private matDialog: MatDialog,
              private errorHandlingService: ErrorHandlingService,
              private currencyPipe: FormatCurrencyPipe,
              gameService: GameService) {
    super(gameService)
  }

  ngOnInit(): void {
    this.uiState = UIStateEnum.ShowLoading;
    this.charityService.charityBalancesPerGame(this.activeGame?.Id!).subscribe({
      next: (res) => {
        this.charityBreakdown = res
        this.uiState = UIStateEnum.ShowData;
      },
      error: (err) => {
        this.uiState = UIStateEnum.ShowData;
        this.errorHandlingService.displayPageLevelErrorMessage(err);
      }
    })
  }

  onMakePaymentClick() {
    this.matDialog.open(MakePaymentDialogComponent,
      {
        data: this.charityBreakdown,
        width: '650px'
      });
  }

  onCharityBreakdownGridReady(params: GridReadyEvent<ICharityPaymentBalance>) {
    this.charityBreakdownGridApi = params.api;
    this.charityBreakdownGridApi.sizeColumnsToFit({
      defaultMinWidth: 90,
    });
  }

}
