import {animate, state, style, transition, trigger} from '@angular/animations';


export const onSideNavChange = trigger('onSideNavChange', [
  state('close',
    style({
      'min-width': '55px'
    })
  ),
  state('open',
    style({
      'min-width': '255px'
    })
  ),
  transition('close => open', animate('300ms ease-in')),
  transition('open => close', animate('500ms ease-in')),
]);


export const onMainContentChange = trigger('onMainContentChange', [
  state('close',
    style({
      'margin-left': '88px'
    })
  ),
  state('open',
    style({
      'margin-left': '278px'
    })
  ),
  transition('close => open', animate('300ms ease-in')),
  transition('open => close', animate('500ms ease-in')),
]);


export const animateText = trigger('animateText', [
  state('hide',
    style({
      'display': 'none',
      opacity: 0,
    })
  ),
  state('show',
    style({
      'display': 'block',
      opacity: 1,
    })
  ),
  transition('show => hide', animate('0ms ease-out')),
  transition('hide => show', animate('200ms ease-in')),
]);
